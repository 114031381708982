// 新着情報
.whats_new {
    border: 1px solid #bfbfbf;
    border-radius: 5px;
    padding: 0!important;
    background-color: $lightgray;
    margin-top: 3rem;
    // max-height: 24rem;
    @include shadow($prototype-box-shadow);
    .whats_new_caption {
        background-color: $blue;
        padding: 0.25rem 1rem;
        h2 {
            font-size: 1.8rem;
            margin-bottom: 0;
            color: #fff;
        }
    }
    .whats_new_data {
        background-color: $white;
        max-height: 20rem;
        overflow: scroll;
        ul.head_news {
            list-style: none;
            margin: 0;
            padding-inline-start: 0;
            li {
                display: flex;
                flex-flow: row wrap;
                &:nth-of-type(odd){
                    background-color:$white;
                    &:hover {
                        background-color:#fff;
                    }
                }
                &:nth-of-type(even){
                    background-color: rgba($color: #B8DBEA, $alpha: .4);
                    &:hover {
                        background-color: darken($color: #E1F0F5, $amount: 5%);
                    }
                }
                a {
                    display: flex;
                    flex-direction: row;
                    padding-left: 0;
                    width: 100%;
                    color: #1c6a97;
                    &:hover,
                    &:focus {
                        color: darken($color: #1c6a97, $amount: 10%);
                    }
                    &::before {
                        content: none;
                    }
                    span {
                    &:nth-of-type(1){
                        width: 30%;
                        padding-top: .25rem;
                        padding-left: 1rem;
                    }
                    &:nth-of-type(2){
                        width: 70%;
                        padding-top: .25rem;
                        padding-bottom: .25rem;
                    }
                }
            }
        }
    }
    }
}