body {
    background-color: #FFFFE6;
}
.banner_quad_top {
    margin-top: 3rem;
}
section {
    margin-bottom: 3rem;
}
.main_format {
    background:$white;
    @include shadow($prototype-box-shadow);
}
.main_format_inner_wrapper {
    padding: 0 3rem 1.5rem;
    @include breakpoint (small only) {
        padding: 0 1rem 1rem;
    }
}
