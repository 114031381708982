

.img-grid {
    margin-bottom: 2rem;
    @include breakpoint (small only) {
        margin-bottom: 1.5rem;
        justify-content: center;
    }
    .cell {
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
        @include breakpoint (small only) {
            margin-bottom: 1rem;
        }
        p.lead {
            margin-bottom: .5rem;
        }
        figure {
            display: table;
            img {
                margin-bottom: .25rem;
            }
            figcaption {
                display: table-caption;
                caption-side: bottom;
                background-color: $lightblue;
                border: 1px solid $light-gray;
                padding: .5rem;
                border-radius: 5px;
            }
        }
    }
}
.float-img-box {
    padding: .5rem 1rem;
    figure {
        display: table;
        figcaption {
            display: table-caption;
            caption-side: bottom;
        }
    }

}
// media-objectの指定追記
.media-object-section {
    img {
    width: 100%;
}
}
// サムネイル付lightbox2の記述
.gallery-pic {
    margin-left: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
        li {
            padding-left: .5rem;
            padding-right: .5rem;
            img {
                @include shadow($prototype-box-shadow);
            }
        }
    // サムネイル縦並び
    &__v {
    flex-direction: column;
        li {
            margin-bottom: 1.5rem;
        }
    }
}
// サムネイル横並び
// 文中にロゴ挿入する際の記述
.logo-icon {
    display: inline-block!important;
    li & {
    margin-left: 1rem;
    }
    figure & {
    margin-right: .5rem;
    }
    &__youtube {
        width: 5rem!important;
    }
}
.img-icon {
    display: inline-block;
    width: 3rem;
}
// ダウンロード制限
.img-hide {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(#{$path}assets/img/common/dummy.png);
        background-position: top left;
        background-size: cover;
    }
    img {
        display: block;
    }
}
