h1,
h2,
h3,
.lead {
    font-family: a-otf-midashi-go-mb31-pr6n, sans-serif;
    font-weight: 600;
    font-style: normal;
}

strong {
    font-size: 120%;
    &.italic {
        font-style: italic;
    }
    &.fs100 {
        font-size: 100%;
    }
}

.thinline {
    font-weight: normal !important;
}

.red {
    color: $red;
}

.jumbo {
    font-size: 400% !important;
}

.bold {
    font-weight: bold;
}

small {
    color: $black !important;
}

.event_index .upper_content {
    text-align: center;
    padding-top: 1rem;

    h1.page_layout {
        display: inline-block;
        position: relative;
        height: 6rem;
        line-height: 6rem;
        text-align: center;
        padding: 0 5rem;
        font-size: 3rem;
        background: $blue;
        color: #FFF;
        box-sizing: border-box;
        margin-bottom: 3rem;

        @include breakpoint (small only) {
            height: 3.6rem;
            line-height: 3.6rem;
            padding: 0 1rem;
            font-size: 1.5rem;
            margin-bottom: 1.5rem;
            width: 100%;
        }

        &::before,
        &::after {
            position: absolute;
            content: '';
            width: 0px;
            height: 0px;
            z-index: 1;
        }

        &::before {
            top: 0;
            left: -1px;
            border-width: 3rem 0 3rem 1.5rem;
            border-color: transparent transparent transparent #FFFFE7;
            border-style: solid;

            @include breakpoint (small only) {
                border-width: 1.8rem 0 1.8rem .9rem;
            }
        }

        &::after {
            top: 0;
            right: -1px;
            border-width: 3rem 1.5rem 3rem 0;
            border-color: transparent #FFFFE7 transparent transparent;
            border-style: solid;

            @include breakpoint (small only) {
                border-width: 1.8rem .9rem 1.8rem 0;
            }
        }
    }
}

.main_format .upper_content {
    text-align: center;
    padding-top: 1rem;
    position: relative;
    margin-bottom: 3rem;

    @include breakpoint (medium) {
        margin-bottom: 1.5rem;
    }

    @include breakpoint (small only) {
        margin-bottom: 1rem;
    }

    h1.page_layout {
        position: relative;
        color: #fff;
        font-size: 3rem;
        background: $blue;
        margin: 0 -1rem;
        padding: .5rem 1rem;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);

        @include breakpoint (medium down) {
            padding: .5rem;
            font-size: 2rem;
        }

        @include breakpoint (small only) {
            font-size: 1.8rem;
        }

        &::before,
        &::after {
            position: absolute;
            top: 100%;
            content: "";
            width: 0;
            height: 0;
            border: 7px solid transparent;
            border-top: 7px solid #000;
            opacity: 0.5;
        }

        &::before {
            left: 0;
            border-right: 7px solid #000;

        }

        &::after {
            right: 0;
            border-left: 7px solid #000;
        }

        & span.subtitle {
            font-size: 1.2rem;
            display: block;
            text-align: left;

            &:last-child {
                text-align: right;
            }
            &.align-center {
                text-align: center!important;
            }
            &.align-left {
                text-align: left!important;
            }
        }
    }
}

section h2 {
    font-size: 2rem;
    border-bottom: 2px solid $blue;
    position: relative;
    margin-top: 3rem;
    margin-bottom: 2rem;

    // padding-left: 1rem;
    @include breakpoint (medium down) {
        font-size: 1.8rem;
        margin-top: 2.4rem;
        margin-bottom: 1.6rem;
    }

    @include breakpoint (small only) {
        font-size: 1.75rem;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
    }
}

section h3 {
    font-size: 1.6rem;
    position: relative;
    margin-top: 1.5rem;
    margin-left: 1.5rem;
    margin-bottom: 1rem;

    &::before {
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        top: 8.5px;
        left: -25px;
        border: 10px solid $blue;

    }

    @include breakpoint (medium down) {
        font-size: 1.55rem;
    }

    @include breakpoint (small only) {
        font-size: 1.5rem;
    }

}

.indent_line {
    display: inline-block;
    margin-left: 3rem;
    // text-indent: -3em;
    position: relative;

    &::before {
        // content: "\2e3b";
        content: "\2500\2500";
        position: absolute;
        left: -2.5rem;
    }
}

// 肩書きの記述
dl small {
    display: block;
    line-height: 1.2;
    font-style: italic;
    margin-left: 1em;
}

.f3rem {
    font-size: 3rem;
}

.fs80 {
    font-size: 60% !important;
}

// マイナスインデント
.indent-1 {
    text-indent: -1em;
    padding-left: 1em;
}

.indent-2 {
    text-indent: -2em;
    padding-left: 2em;
}

.indent-3 {
    text-indent: -3em;
    padding-left: 3em;
}

.indent-4 {
    text-indent: -4em;
    padding-left: 4em;
}

.indent-5 {
    text-indent: -5em;
    padding-left: 5em;
}

a.disabled {
    &:hover {
        border-bottom: none !important;
        cursor: not-allowed!important;
        span {
            border-bottom: none !important;
        }
    }
}
.underline-dotted {
    border-bottom: 1px dotted $black;
}
del.red-line {
    text-decoration-color: $red;
    text-decoration-thickness: 2px;
}
del > a:hover {
    color: inherit!important;
}
span.marker-cream {
    background-color: $cream-yellow;
    padding: .25rem;
    color: $black;
    font-weight: bold;
}