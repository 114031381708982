// Float
.fl {
    float: left;
}
.fr {
    float: right;
}
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}
// width utility
.w5 {
  width: 5%;
}
.w10 {
  width: 10%;
}
.w15 {
  width: 15%;
}
.w20 {
  width: 20%;
}
.w25 {
  width: 25%;
}
.w30 {
  width: 30%;
}
.w35 {
  width: 35%;
}
.w40 {
  width: 40%;
}
.w45 {
  width: 45%;
}
.w__50 {
  width: 50%;
}
.w55{
  width: 55%;
}
.w60 {
  width: 60%;
}
.w65 {
  width: 65%;
}
.w70 {
  width: 70%;
}
.w75 {
  width: 75%;
}
.w80 {
  width: 80%;
}
.w85 {
  width: 85%;
}
.w90 {
  width: 90%;
}
.w95 {
  width: 95%;
}
.w-sp100 {
  @include breakpoint (small only){
    width: 100%;
  }
}
// 右揃え（flexbox）
.align-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.same-height {
  img {
    width: 100%;
    object-fit: contain;
  }
}