.title-bar.show-for-large{
    .top-bar-title {
        width: 100%;
        ul.menu.medium-horizontal.dropdown{
            justify-content: space-between;
            width: 100%;
            > li {
                align-items: center;
                &:first-child a:hover {
                    text-decoration: none;
                }
                > a {
                    color: white;
                    line-height: 1.35;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                    ul.menu.vertical.submenu {
                        background-color: $white;
                        min-width: 280px;
                        li{
                            border-bottom: 1px solid $gray;
                          &:last-child {
                              border-bottom: none;
                          }
                          &:hover {
                              background-color: rgba($color: $blue, $alpha: .1);
                          }
                          &:hover a {
                              color: $black;
                          }
                          a {
                              line-height: 1.75;
                              display: block;
                              width: 100%;
                            padding: .25rem .5rem;
                          }
                        }
                    }
            }
    } 
}
}