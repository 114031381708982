.title-bar.hide-for-large h1 img {
    width: 200px;
    margin: .5rem auto 0 .5rem;
}

.eyeCatch {
    background-color: #CBEFFF;
    height: 15rem;
    padding: 0 3rem;
    @include breakpoint (small only) {
        padding: 0 .5rem;
        height: 9rem;
    }
    .top_icon {
        background-image: url(#{$path}assets/img/common/bg_top_icon.png);
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;
        padding: 1rem 2rem;

            h1 {
                a {
                    display: block;
                    &:hover {
                        outline: 1px dotted $black;
                    }
                    img {
                        width: 15rem;
                        margin: 0 2rem;
                    }
                }
            }
    }
    .top_bg {
        background-image: url(#{$path}assets/img/common/bg_header.png);
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: contain;
        @include breakpoint(medium down)
        {
            background-position: 0 85%;
        }
        .top_icon_sp {
            width: 24rem;
            height: 100%;
            p{
                height: 100%;
                position: relative;
                img {
                    position: absolute;
                    bottom: 2px;
                    width: 80%;
                }
             }
        }
        }
} 
