
ul.tabs.tabs_menu_parent {
    border-radius: 15px 15px 0 0;
    border-bottom: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    li.tabs-title {
        float: none;
         width: 25%;
        border-right: 1px solid #fff;
        text-align: center;
        &:last-child {
            border-right: none;
        }
        >a {
            background-color: #555555;
            color: #fff;
            width: 100%;
            height: 100%;
            font-size: 1.2rem;
            font-weight: bold;
            text-decoration: none;
            @include breakpoint (small only) {
                padding: .5rem;
                font-size: 1rem;
            }
            &:focus,
            &[aria-selected='true'] {
                background-color: #fff;
                color: #000;
            }
            &:hover {
                background-color: $gray;
            }
        }
        &:first-child >a {
            border-radius: 15px 0 0 0;
        }
        &:last-child >a {
            border-radius: 0 15px 0 0;
        }
        
    }
}
.tabs-content.tabs-content-child {
    border: none;
}
.tabs-content {
    ul.tabs.tabs_menu_child {
        border: none;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        margin-bottom: 2rem;
        li.tabs-title {
            text-align: center;
            width: 20%;
            border-right: 1px solid #acacac;
            &:last-child {
                border-right: none;
            }
            &.disabled {
                font-size: 1.2rem;
                color: #757575;
                padding: 1.25rem 1.5rem;
                line-height: 1;
                @include breakpoint (small only) {
                    padding: .5rem;
                    font-size: 1rem;
                }
            }
            a {
                font-size: 1.2rem;
                background-color: transparent;
                color: #757575;
                text-decoration: none;
                @include breakpoint (small only) {
                    padding: .5rem;
                    font-size: 1rem;
                }
                &:focus,
                &[aria-selected='true'] {
                    color: #000;
                    font-weight: bold;
                    span {
                        border-bottom: 3px solid #d81200;
                    }
                }
                &:hover {
                    span {
                        border-bottom: 3px solid $gray;
                    }
                }
            }
        }
    }

}