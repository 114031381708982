.off-canvas {
    background-color: rgba($color: $gray, $alpha: .9);

    &.is-transition-overlap {
        z-index: 100013;
    }

    &.position-right {
        width: 300px;
    }

    .sp-menu-wrapper {
        height: 100%;
        button.close-button {
            color: #fff;
        }

        img {
            padding: .5rem;
            width: 80%;
        }

        ul.accordion-menu {
            margin-top: 1rem;
            padding-left: .5rem;
            padding-right: .5rem;

            >li {
                border-bottom: 1px solid #fff;

                a {
                    color: #fff;
                    font-size: 90%;
                    font-weight: bold;
                    line-height: 1.4;

                    &:hover {
                        color: #e0ffff;
                        text-decoration: underline;
                    }
                }

                .nested {
                    border-top: 1px solid $light-gray;
                    margin-left: 0;
                    ;

                    >li {
                        border-bottom: 1px dotted $light-gray;

                        &:last-child {
                            border-bottom: none;
                        }

                        a {
                            font-weight: normal;
                        }
                    }
                }
            }
        }
        
    }
    .sp-serchform {
        display: flex;
        justify-content: center;
        padding-bottom: 2rem;
        form {
            min-width: 240px;
            .gsc-input {
                .gsc-input-box {
                    padding-top: 1.5px;
                    padding-bottom: 0.5px;
                }
            }
            .gsc-search-button {
                button {
                    padding: 7.5px 12px 8px 12px
                }
            }
        }
    }
}

.js-off-canvas-overlay {
    background-color: rgba($color: #000000, $alpha: .7);
}
