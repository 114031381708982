$red : #d50000;
$blue : #207CB1;
$lightblue : #caeffe;
$skyblue : #86C5E9;
$link-blue : #1D709F;
$green : #00552a;
$white : #fdfdfd;
$gray : #757575;
$lightgray : #d0d0d0;
$beige : #ffffE6;
$brown : #c7996d;
$black : #0a0a0a;
$yellow : #ffff00;
$cream-yellow: #FFEDB3;
$cadet : #007EAD;
$pale-blue : #44A6DE;
$coral : #E58C8A;
$green-munsel : #00A375;
$honey-yellow : #f6ae2d;