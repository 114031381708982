// イベントカテゴリトップページ
.thumbnail_cards .card .fa_icon {
    position: absolute;
    z-index: 10;
    top: 5px;
    color: #cc3300;
    font-size: 2.5rem;
    margin-left: .5rem;
    padding: 1px;
    background-color: rgba($color: #fff, $alpha: .5);

}
