footer {
    background-color: #C69969;
    position: relative;
    border-top: 1px solid #000;
    padding: .5rem;
    img {
        width: 200px;
        position: absolute;
        z-index: 1;
        top: -59px;
        right: 0;
    }
    .grid-x {
        margin-bottom: 1rem;
        .cell {
            margin-top: 1rem;
            z-index: 10;
            ul.footer_nav {
                list-style: none;
               > li {
                    margin-bottom: 1rem;
                    @include breakpoint (small only) {
                        margin-bottom: .5rem;
                    }
                    &::before {
                        content: "\200B";
                    }
                    a {
                        color: #000;
                        text-decoration: underline;
                        &:hover {
                            color: #00008b;
                        }
                    }
                    & ul {
                        list-style: none;
                        li {
                            font-size: 95%;
                            text-indent: -1rem;
                            &::before {
                                content: "\fe58";
                            }
                        }
                    }
                }
            }
        }
        hr {
            margin: 0 auto;
        }
        .text-center {
            padding: .5rem 0 1rem 0;
        }
    }
}