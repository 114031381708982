.thumbnail_cards {
    .card {
        max-width: 240px;
        position: relative;
        @include shadow($prototype-box-shadow);
        transition: 0.2s all ease 0s;
        padding: 0;
        overflow: visible;
         &:hover {
             transform: translate3d(1px, 2px, 0);}
        a {
        .card-section {
            position: absolute;
            bottom: 0;
            background: rgba($color: #fff, $alpha: .8);
            padding: .25rem 0.5rem;
                color: #2e456d;
                p.date {
                    background-color: $red;
                    color: $white;
                    display: inline-block;
                    padding: 2px 5px;
                }
                h2 {
                    font-size: 1rem;
                    margin-top: 0;
                }
                p {
                    font-size: 80%;
                    margin: 0;
                }
            }
        }
    } 
    .card_desc {
        background-color: #3E7AAD;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        a {
            color: #fff;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            text-decoration: none;
            box-sizing: border-box;
            &:hover {
                color: #fff;
            }
            h2 {
                font-size: 1.2rem;
            }
            p {
                font-size: 80%;
                margin: 0;
            }
            p.date {
                text-align: right;
            }
        }
       }
}