.pc-sideNav-wrapper {
    padding: 0 !important;
    margin: 3rem 0 0 0 !important;

    .sideNav {
        width: 100%;

        @include breakpoint(large) {
            list-style-type: none;
            border: 1px solid $gray;
            @include shadow($prototype-box-shadow);

            >li {
                background-color: $white;
                // font-size: 1.25rem;
                border-bottom: 1px solid $gray;
                border-left: 2rem solid $blue;
                position: relative;
                line-height: 1.7;

                &.current {
                    font-weight: bold;
                    background-color: $skyblue;
                    padding: .5rem;
                }

                &.current::before {
                    position: absolute;
                    content: "\f105";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    font-size: 1.2rem;
                    top: .5rem;
                    left: -1.25rem;
                    color: $yellow;
                }

                &.current:hover:before {
                    left: -1rem;
                    transition-duration: 0.5s;
                }

                &:last-of-type {
                    border-bottom: none;
                    padding: .5rem;
                    // display: flex;
                    // align-items: center;
                    // justify-content: center;
                }


                a {
                    text-decoration: none;
                    color: $black;
                    padding: .5rem;
                    // background-color: $lightgray;
                    display: block;
                    line-height: 1.7;

                    &:hover {
                        background-color: $skyblue;
                    }
                }

                button.submenu-toggle {
                    // background-color: $lightblue;

                    &::after {
                        border-top-color: $blue;

                    }
                }

                .nested {
                    margin-left: 0 !important;

                    li {
                        border-top: 1px solid $gray;
                        padding-left: 1rem;
                        background-color: $white;
                        line-height: 1.7;

                        &:hover {
                            background-color: $skyblue;
                        }

                        &:last-of-type {
                            border-bottom: 1px solid $gray;
                        }

                        &.current {
                            background-color: $skyblue;
                            position: relative;
                            padding: .5rem .5rem .5rem 1.5rem;
                            font-weight: bold;

                            &::before {
                                position: absolute;
                                content: "\f105";
                                font-family: "Font Awesome 5 Free";
                                font-weight: 900;
                                font-size: 1.2rem;
                                top: .5rem;
                                left: -1.25rem;
                                color: $yellow;
                            }

                            &>a {
                                font-weight: bold;
                                background-color: transparent;
                                line-height: 1.7;
                                padding: .5rem;
                            }
                        }

                    }
                }
            }
        }

        @include breakpoint(medium down) {
            margin-bottom: 1rem;
            background-color: $white;
            border-left: 20px solid $blue;

            li {
                padding: 0.5rem 1rem;
                line-height: 1.2;
                position: relative;
                // font-size: 1.2rem;

                &::after {
                    position: absolute;
                    content: "|";
                    right: 0;
                }

                &:last-of-type::after {
                    content: none;
                }

                a {
                    padding: 0;
                    line-height: 1.2;
                    text-decoration: underline;
                }
            }
        }

    }
}