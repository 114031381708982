form {
    .form_contact {
        padding-top: 3rem;
        label {
            color: $gray;
        }
        p.help-text {
            text-align: right;
        }
    }
        button {
            @include breakpoint (medium) {
                width: 40%;
            }
        }
}