// Hide sidebar when printing
 @media print {
    .pc-sideNav-wrapper {
        display: none;
    }
    .main_area.large-9 {
        width: 100%;
    }
    .stack-for-small .media-object-section img {
        max-width: 100%!important;
    }
 }