.accordion {
    background-color: $lightblue;
    li {
        a.accordion-title {
            color: $black;
            text-decoration: none;
            font-size: 1rem;
            border: 1px solid $lightgray;
            border-bottom: none;
            &:hover
             {
                background-color: $blue;
                color: $white;
            }
            &:focus
             {
                background-color: inherit;
                color: inherit;
            }
            &::before {
                font-size: 1rem;
            }
        }
        &:last-child a {
            border-bottom: 1px solid $lightgray;
        }
        &.is-active a.accordion-title{
            background-color: lighten($color: $blue, $amount: 20%);
            color: #000;
        }
    }
}