// animation fx
.press-action {
    transition: 0.2s all ease 0s;
    &:hover {
        transform: translate3d(0, 3px, 0);
    }
}
.main_area a.button,
.main_area .button-group a.button {
    text-decoration: none!important;
    &:hover {
        color: $white;
    }
    &.warning,
    &.success {
        color: $black;
    }
}
.exlarge {
    font-size: 150%!important;
}
.button.paleblue {
    background-color: $pale-blue;
    color: $black;
    &:hover,
    &:focus {
        background-color: $cadet;
        color: $black!important;
    }
}
.button.coral {
    background-color: $coral;
    color: $black;
    &:hover,
    &:focus {
        background-color: lighten($color: $coral, $amount:10);
        color: $black!important;
    }
}
.button.green-munsel {
    background-color: $green-munsel;
    color: $black;
    &:hover,
        &:focus {
        background-color: lighten($color: $green-munsel, $amount:10);
        color: $black!important;
    }
}
.button.honey-yellow {
    background-color: $honey-yellow;
    color: $black;
    &:hover,
    &:focus {
        background-color: lighten($color: $honey-yellow, $amount:10);
        color: $black!important;
    }
}
