.banner-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    @include breakpoint (small only) {
        flex-direction: column;
    }
    > li {
        // border: 1px solid $light-gray;
        border-radius: 10px;
        // background-color: rgba($color: $blue, $alpha: .2);
        background-color: $lightblue;
        min-width: 320px;
        min-height: 140px;
        margin-right: 1rem;
        @include breakpoint (small only) {
            margin-right: 0;
            margin-bottom: 1rem;
        }
        &:last-child {
            margin-right: 0;
        }
        @include shadow($prototype-box-shadow);
        a {
            display: flex;
            justify-content: space-between;
            padding: 1rem 0.5rem;
            text-decoration: none;
            color: #000;
            &:hover {
                color: inherit;
            }
            p {
                line-height: 1.4;
                // font-size: 140%;
                flex-basis: 60%;
                @include breakpoint (small only) {
                    font-size: 150%;
                }
            }
            img {
                object-fit: contain;
                width: 0%;
                flex-basis: 40%;
                max-width:100%;
            }
        }
    }
}