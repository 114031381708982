.w5 {
    width: 5%!important;
}
.w10 {
    width: 10%!important;
}
.w15 {
    width: 15%!important;
}
.w20 {
    width: 20%!important;
}
.w25 {
    width: 25%!important;
}
.w30 {
    width: 30%!important;
}
.w35 {
    width: 35%!important;
}
.w40 {
    width: 40%!important;
}
.w45 {
    width: 45%!important;
}
.w50 {
    width: 50%!important;
}
.w55 {
    width: 55%!important;
}
.w60 {
    width: 60%!important;
}
.w65 {
    width: 65%!important;
}
.w70 {
    width: 70%!important;
}
.w75 {
    width: 75%!important;
}
.w80 {
    width: 80%!important;
}
.w85 {
    width: 85%!important;
}
.w90 {
    width: 90%!important;
}
.w95 {
    width: 95%!important;
}
.w1-3 {
    width: calc(100% / 3);
}
.w1-6 {
    width: calc(100% / 6);
}
.w1-7 {
    width: calc(100% / 7);
}
.w1-8 {
    width: calc(100% / 8);
}
.w1-9 {
    width: calc(100% / 9);
}
.w100-sp {
    @include breakpoint (small only) {
        width: 100%!important;
    }
    @media print{
        width: inherit!important;
    }
}