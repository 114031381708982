#mainContent .grid-container .grid-x .module_carousel {
    border-radius: 15px;
    background-color: #bee0f3;
    padding-top: 1rem;
    @include shadow($prototype-box-shadow);
    margin-top: 3rem;

    .slickPanel {
        height: 100%;

        a {
            padding-left: 0;

            &:focus img {
                border: 5px solid $blue;
            }

            figure {
                position: relative;

                .img-wrapper {
                    img {
                        width: 100%;
                        object-fit: cover;
                    }
                }

                figcaption {
                    position: absolute;
                    bottom: 0;
                    background-color: rgba($color: #000000, $alpha: .7);
                    color: #fff;
                    width: 100%;
                    padding: .5rem 1rem;

                    a {
                        color: $skyblue;
                        text-decoration: underline;

                        &:hover {
                            color: $lightblue;
                        }
                    }
                }
            }

            .slick-text-wrapper {
                padding: 1rem;
                background-color: $lightblue;

                .slick-text {
                    h2 {
                        font-size: 1.5rem;
                    }

                    p {
                        color: $black;
                    }
                }

                .caption-wrapper {
                    background-color: rgba($color: #000000, $alpha: .7);
                    color: #fff;
                    width: 100%;
                    padding: .5rem 1rem;

                    p {
                        margin-bottom: 0;
                    }
                }
            }

        }
    }

    .slick_controller {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        button {
            padding: .2rem .7rem;
            background-color: $gray;
            margin-bottom: .5rem;

            &:hover {
                background-color: darken($color: $gray, $amount: 30%);
            }

            &:first-child {
                margin-right: .5rem;
            }

            i {
                font-size: 1rem;
                color: $white;
            }
        }
    }

    .slick-prev,
    .slick-next {
        color: $black;
    }
}