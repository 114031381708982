

// 矢口家の紹介
.yaguchi_family {
    margin-top: 3rem;
    .top_banner_large {
        border: 1px solid #bfbfbf;
        text-align: center;
        position: relative;
        @include shadow($prototype-box-shadow);
        .speech_bubble {
            position: absolute;
            top: 15px;
            left: 10px;
            @include breakpoint (xlarge) {
                width: 250px;
            }
            @include breakpoint (large only) {
                width: 210px;
            }
            @include breakpoint (medium only) {
                width: 250px;
            }
            @include breakpoint (small only) {
                width: 50%;
            }
        }
        a.button {
            position: absolute;
            top: 5%;
            right: 5%;

        }
        img.bg_yaguchi_family {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}