dl.horizontal_dl {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    &__2080 {
        dt {
            width: 20%;
        }
        dd {
            width: 80%;
        }
    }
    &__3070 {
        dt {
            width: 30%;
        }
        dd {
            width: 70%;
        }
    }
    &__4060 {
        dt {
            width: 40%;
        }
        dd {
            width: 60%;
        }
    }
    &__5050 {
        dt {
            width: 50%;
        }
        dd {
            width: 50%;
        }
    }
    &__6040 {
        dt {
            width: 60%;
        }
        dd {
            width: 40%;
        }
    }
    dt,
    dd {
        @include breakpoint (small only) {
            width: 100%;
        }
    }
    dd {
        margin-bottom: 1rem;
        @include breakpoint (small only) {
            margin-left: 1rem;
        }
    }
}
dl.indent_guide {
    margin-left: 1rem;
    dd {
        margin-left: 2rem;
    }
    &__ellipsis {
        dd {
            &::before {
                content: "\22ef\22ef\22ef";
            }
        }
    }
}
.link-list {
    list-style: none;
    display: flex;
    &__horizontal {
        flex-direction: row;
        flex-wrap: wrap;
        @include breakpoint (small only) {
            flex-direction: column;
        }
    }
    &__vertical {
        flex-direction: column;
    }
    li {
        margin-right: 2rem;
        margin-bottom: 1rem;
        text-indent: -1.25rem;
        margin-left: 1.25rem;
        &:last-child {
            margin-right: 0;
        }
        &::before {
            font-family: $font-icon;
            content: $link-arrow;
            font-weight: 900;
            color: $blue;
            margin-right: 5px;
        }
        a {
            text-decoration: underline;
            i.fas {
                display: inherit;
            }
        }
    }
    &.align-right {
        align-items: flex-end;
    }
}
ul.list_notice {
    list-style: none;
    li {
        margin-left: 2rem;
        font-size: 90%;
        text-indent: -1rem;
        &::before {
            content: "※";
            margin-right: 5px;
        }
        i.fas::before {
            padding-left: 1rem;
        }
    }

    &__red {
        li::before {
            color: $red;
        }
    }
}

// pdfファイルの拡張子別アイコン生成
a[href$=".pdf"],
a[href$=".xlsx"],
a[href$=".docx"]  {
    position: relative;
    padding-left: 5px;
    &::before {
        position: absolute;
        font-family: $font-icon;

        font-weight: 900;

    }
    p & {
        padding-left: 1rem;
        &::before {
            left: 0;
        }
    }
    li & {
        padding-left: 1rem;
        &::before {
            padding-left: 0;
            left: 0;
        }
    }
    .link-list li & {
        padding-left: 1rem;
        &::before {
            padding-left: .2rem;
            left: auto;
        }
    }
    .horizontal_dl dd & {
        padding-left: 1rem;
        &::before {
            padding-left: 0;
            left: 0;
        }
    }
    .list_notice &::before{
        left: auto;
    }
    .tabs-panel .card & {
        
        &::before {
            font-size: 3rem;
            left: .5rem;
            top: -.5rem;
        }
    }
    .module_carousel .slick-slider .slick-list .slickPanel & {

        content: none;
    }
}

    a[href$=".pdf"]::before {

        content: $pdf;

        color: $red;
    }
    a[href$=".docx"]::before {

        content: $word;

        color: $blue;
    }
        a[href$=".xlsx"]::before {

        content: $excel;

        color: $green;
    }
    // list style none
    .no-list {
        list-style: none;
    }
    .circle-number {
        list-style: none;
        li {
            margin-left: .5em;
            text-indent: -1.5em;
            span {
                margin-right: .5em;                
            }
        }
    }
    // リンク無効化
    .link-disable {
        pointer-events: none!important;
        user-select: none!important;
    }
    // dl 縦位置中央
    .v-middle {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        dt {
            width: 20%;
        }
        dd {
            width: 80%;
        }
        dt, dd {
            margin-bottom: 1em;
        }
    }
    // 点線　下線
    .dotted-bottom {
        list-style: none;
         li {
             border-bottom: 2px dotted $gray;
         }
    }
    // （注）リスト
    .caution-jp {
        list-style: none;
        padding-left: 1em;
        li {
            text-indent: -3em;
        }
    }

    .d-flex-between {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;}