.table-scroll table {
    @include breakpoint (small only) {
        min-width: 600px;
    }
}

.main_format .main_format_inner_wrapper table {
    table-layout: fixed;
    width: 100%;
    overflow-wrap: break-word;

    thead {
        border: 1px solid #d3d3d3;

        th {
            text-align: center;
            background-color: #2a7ab0;
            color: #fff;
            border-right: 1px solid $white;

            &:last-child {
                border-right: none;
            }
        }
    }

    tbody {
        border: 1px solid #d3d3d3;

        tr:nth-child(even) {
            background-color: #DDEBF6;

            a {
                color: #1C6D9B;
            }

            &:hover {
                background-color: rgba($color: #DDEBF6, $alpha: .7);
            }
        }

        th {
            background-color: #247CB2;
            color: #fff;
            border-top: 1px solid $white;
            text-align: left;
        }

        td {
            border-right: 1px solid $lightgray;

            &:last-child {
                border-right: none;
            }
        }
    }
}

// レイアウト
// 1列目のtdが中央揃え、右揃えのtbody
tbody.col1st {
    &__align--center {

        tr th:first-child,
        tr td:first-child {
            text-align: center;
        }
    }

    &__align--right {

        tr th:first-child,
        tr td:first-child {
            text-align: right;
        }
    }
}

table.table_adviser {
    tbody {
        tr>td {

            &:first-child,
            &:nth-child(4) {
                text-align: center;
            }
        }
    }
}

// 金融広報アドバイザー　プロフィール
.advisor-prof {
    tbody {
        td {
            p {
                text-align: center;
                font-weight: bold;
                margin-bottom: 0;
                font-size: 120%;
            }

        }
    }
}
