// nprint header hide when normal mode {
.print-logo {
    display: none;
}
@media print {
    // hide url when printing
    a[href*="/"]:after, a[href*="/"]:visited:after {content: ""!important;}
    // hide when printing
    header,
    nav,
    footer
     {
        // a[href]:after {
        //     content: '' !important;
        //     ;
        // }
        display: none;
    }
    //  show when printing
    .print-logo {
        display: block;
        padding: 1rem;
        img {
            width: 150px;
        }
    }
}
