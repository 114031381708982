.blockskip-link {
    font-size: 3rem;
}
a:focus,
button:focus {
    outline-offset: 5px;
    outline: 2px dotted inherit;
    .whats_new_data li & {
        outline-offset: -5px;
    }
}
// .primary a {
//     color: #1B6FA0;
// }
input::placeholder,
textarea::placeholder {
    color: $gray;
}
a {
    color: $link-blue;
}