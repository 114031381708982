// Top 4面カードバナー
.banner_quad_top {
    .cell {
        padding: 2rem;
        @include breakpoint (small only) {
            padding: 1rem .5rem;
        }
        .banner_quad_card {
            background-color: #fff;
            border: 1px solxx   id #505050;
            border-radius: 15px;
            padding: 1rem 1.5rem 0.5rem 1.5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @include shadow($prototype-box-shadow);
            .card_header {
                
                .card_header_title {
                    padding: 0;
                    h2 {
                        font-size: 2rem;
                        @include breakpoint (small only) {
                            font-size: 1.75rem;
                        }
                    }
                }
                .card_header_image {
                        text-align: right;
                        padding: 0;
                        width:45%;
                        @include breakpoint (small only) {
                            width: 50%;
                        }
                        img {
                            max-height: 160px;
                        }
                    }
                }
            }
            .card_section {
                border-top: 1px solid gray;
                padding-top: 1rem;
            p {
                margin-bottom: 0;
                line-height: 1.4;
           }
        }
    }
}
.banner_quad_top a {
    text-decoration: none;
    .banner_quad_card {
        transition: 0.2s all ease 0s;
    }
    &:hover,
    &:focus {
        .banner_quad_card {
            box-shadow: none;
            transform: translate3d(5px, 3px, 0);
        }
    }
}

// Top 関連リンクバナー
.banner_link {
    margin-bottom: 3rem;
        .cell {
            margin-bottom: 2rem;
            a {
                color: $black;
                font-size: 90%;
                text-align: center;
                text-decoration: underline;
                display: inline-block;
                &:hover {
                    outline: 1px dotted $blue;
                    outline-offset: 5px;
                }
                > span {
                    display: block;
                }
                img {
                    width: 240px;
                    &.border-img {

                        border: 1px solid $gray;
                    }
                }   
            }
    }
}